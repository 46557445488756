import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import app_show_logo from '../../assets/images/articles/mobileiot/app-show-logo.png'
import iot_italy_logo from '../../assets/images/articles/mobileiot/iot-italy-logo.png'
import things_logo from '../../assets/images/articles/mobileiot/things-logo.png'

const DesigningMobileIOT = props => (
  <Layout>
    <Helmet
      title="Designing mobile apps for IoT products"
      meta={[
        {
          name:
            'Article about best practises for design mobile apps in the IoT domain',
          content: 'Articles',
        },
      ]}
      bodyAttributes={{
        class: 'articles',
      }}
    />

    <div id="main" className="alt">
      <section id="article-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.coverMobileIOT.childImageSharp.fluid} />
          </span>
        </div>
      </section>
      <div class="post-wrapper">
        <div class="post-content">
          <header>
            <h1>designing mobile apps for IoT products.</h1>
            <h3>
              Tomorrows home will be a helpful home; a home that works with
              services built around and for humans rather than technology. How
              does the approach for UX/UI design of a digital touchpoint
              (smartphone) change for connected & smart products?
            </h3>
          </header>
          <section className="header-post">
            <small>DECEMBER 2017 - 3 mins read</small>

            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * The following guidelines and views were presented by the
                  design team of Things, MIlan at the Appshow 2017 - European
                  App Economy Congress, in partnership with IoTItaly on 5th
                  December 2017.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  <img className="span-4" src={things_logo} alt="things logo" />
                  <img
                    className="span-7"
                    src={app_show_logo}
                    alt="app show logo"
                  />
                  <img
                    className="span-1"
                    src={iot_italy_logo}
                    alt="iot italy logo"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <p className="full-text">
              The relationship between technology and society is becoming
              becomes more intrinsically intertwined. What kind of mobile apps
              are required to unleash the potential of IoT.
            </p>
            <h4>Here are my top 8 guidelines.</h4>
            <div className="image">
              <Img fluid={props.data.mobileiot4.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              The mobile app augments the physical experience as it connects the
              digital world with the physical world. This makes it vital to
              create an experience that is seamless across the different devices
              and contexts.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot5.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              IoT products are powered by users data collected from sensor-rich
              devices and mobile apps. Data is about how, where, when and what
              users do. This data can be fed back to the user in an infographic
              style to provide them with useful insights and suggestions on
              which they can act upon.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot6.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              By considering who is in the room, what their music preferences
              are and the current mood, Prizm always knows what track to play.
              Attributes such as geo location, time of day, weather, heart rate
              & even sentiment can be used to understand the context of users’
              lives. These contextually aware apps allow the delivery of
              appropriate & relevant value at the right time to meet the
              changing needs of the user.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot7.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              Convenience culture is redefining everyday expectations around
              real-time & on-demand services that are seamless, efficient &
              quick. An IoT app connected to the cloud network is able to adapt
              & react to changes in the environment or users behavioural
              patterns. This gives the user the ability to access information &
              value anywhere in real-time when they need it.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot8.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              An IoT app can create a detailed profile of the user, such as
              their preferences & habits data, in order to attain deep
              understanding at a more intimate & individual level. This allows
              the app to curate choices & deliver personalised value tailored to
              the transient needs and behaviour of each user, or help them
              discover new things they may like.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot9.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              Through AI & machine learning the app learns about the user and
              evolves all the time to predict & anticipate what the user will
              need next. This allows the app to remove unnecessary cognitive
              load by automating low-maintenance decisions and daily mundane
              tasks we do in our everyday lives.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot10.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Although an IoT mobile app involves complexities on the back-end,
              the front-end interface of the app need to be clean, simple &
              intuitive. In addition app interface and device interface should
              be consistent, to make the experience continuous and seamless.
            </p>
            <div className="image">
              <Img fluid={props.data.mobileiot11.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              By explaining why your app wants certain type of data and what the
              benefits for the customer will be, they will be more likely to
              share their highly valued information. Apps can reduce the
              implicit risk to users of IoT by increasing:
              <ol>
                <li>Transparency: Let me see what is happening to my data. </li>
                <li>User autonomy: Let me control my data.</li>
                <li>
                  Privacy & Security: Don’t leave holes in my personal network.
                </li>
              </ol>
            </p>
          </section>
          <section className="takeaways">
            <span class="project-step-number">01</span>
            <h2>takeaways</h2>
            <p className="full-text">
              As Smart homes aim to provide enhanced convenience and comfort,
              energy efficiency, security and surveillance. Apps on digital
              touchpoints such as mobile phones will need to play a key role in
              seamlessly integrating in our daily lives and make other connected
              devices meaningful, responsive or even visible at all.
            </p>
          </section>
          {/* <section className="article-social">
            <div className="social inner">
              <p className="article-social-heading">share this post</p>
              <ul className="icons">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/sagarsatam13"
                    className="icon alt fa-twitter"
                  >
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/sagar.satam/"
                    className="icon alt fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://in.pinterest.com/sagarsatam/"
                    className="icon alt fa-pinterest"
                  >
                    <span className="label">Pinterest</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/satamsagar/"
                    className="icon alt fa-linkedin"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </section> */}
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/my-thinking"> my thinking</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <div className="tiles__next">next story</div>
        <div className="tiles__wrapper">
          <div className="tiles__content">
            <div className="tiles__info">
              <a href="/articles/service-with-touchpoints">
                <h2 className="tiles__title">
                  a service with 5000 touchpoints.
                </h2>
              </a>
              <small>DECEMBER 2017 - 3 mins read</small>
              <div className="tiles__text">
                Meet the dabbawallas of Mumbai, one of the most efficient
                systems in the world, a Harvard case study, certified Six Sigma
                by Forbes. Delivering over 200 thousand lunches (dabbas) every
                day, that's 120 tonnes of food daily. Here is my take on how
                this 125 year old service can be innovated.
              </div>
            </div>
          </div>
          <div className="tiles__image">
            <a href="/articles/service-with-touchpoints">
              <Img fluid={props.data.otherarticle.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DesigningMobileIOT

export const fluidMobileIOTImage = graphql`
  fragment fluidMobileIOTImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    coverMobileIOT: file(
      relativePath: { eq: "articles/mobileiot/cover-mobileiot.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot1: file(
      relativePath: { eq: "articles/mobileiot/mobileiot1.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot2: file(
      relativePath: { eq: "articles/mobileiot/mobileiot2.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot3: file(
      relativePath: { eq: "articles/mobileiot/mobileiot3.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot4: file(
      relativePath: { eq: "articles/mobileiot/mobileiot4.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot5: file(
      relativePath: { eq: "articles/mobileiot/mobileiot5.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot6: file(
      relativePath: { eq: "articles/mobileiot/mobileiot6.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot7: file(
      relativePath: { eq: "articles/mobileiot/mobileiot7.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot8: file(
      relativePath: { eq: "articles/mobileiot/mobileiot8.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot9: file(
      relativePath: { eq: "articles/mobileiot/mobileiot9.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot10: file(
      relativePath: { eq: "articles/mobileiot/mobileiot10.png" }
    ) {
      ...fluidMobileIOTImage
    }
    mobileiot11: file(
      relativePath: { eq: "articles/mobileiot/mobileiot11.png" }
    ) {
      ...fluidMobileIOTImage
    }
    app_show_logo: file(
      relativePath: { eq: "articles/mobileiot/app-show-logo.png" }
    ) {
      ...fluidMobileIOTImage
    }
    iot_italy_logo: file(
      relativePath: { eq: "articles/mobileiot/iot-italy-logo.png" }
    ) {
      ...fluidMobileIOTImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidMobileIOTImage
    }
    otherarticle: file(relativePath: { eq: "projects/dabbawalla.png" }) {
      ...fluidMobileIOTImage
    }
  }
`
